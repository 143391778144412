import $ from 'jquery';
import { throttle } from 'throttle-debounce';
import { WOW } from 'wowjs';
const wow = new WOW();
wow.init();

var lastId;
const menuItems = [
  ...document.querySelectorAll('.scroll-down'),
  ...document.querySelector('.menu__brand').querySelectorAll('a'),
  ...document.querySelector('.menu__nav').querySelectorAll('a'),
];

const scrollItems = ['#top'];
menuItems.forEach(function (cItem) {
  var item = cItem.getAttribute('href');
  if (item.length) {
    scrollItems.push(item);
  }

  cItem.addEventListener('click', function (e) {
    e.preventDefault();

    let offsetElementToTop = document.querySelector(
      this.getAttribute('href')
    ).offsetTop;

    if (
      document.documentElement.scrollTop <
        document.querySelector('.hero').offsetHeight &&
      document.querySelector('body').classList.contains('stick-menu')
    ) {
      offsetElementToTop -= document.querySelector('.menu').offsetHeight;
    }

    window.scroll(0, offsetElementToTop - 75);
    if (document.querySelector('body').classList.contains('menu-open')) {
      document.querySelector('body').classList.remove('menu-open');
    }
  });
});

window.addEventListener(
  'scroll',
  throttle(10, () => {
    stickMenu();
    currentMenuItem();
  })
);

currentMenuItem();
stickMenu();

document.querySelectorAll('.btn-plus').forEach(function (btn) {
  btn.addEventListener('click', function () {
    let target = this.dataset.target;
    document.querySelectorAll('div[data-content]').forEach(function (elm) {
      elm.style.display = 'none';
    });
    document.querySelector('div[data-content="' + target + '"]').style.display =
      'block';

    document.querySelector('body').classList.add('modal-open');
  });
});

$('.content-modal__close').on('click', function () {
  document.querySelector('body').classList.remove('modal-open');
  document.querySelectorAll('div[data-content*=""]').forEach(function () {
    this.style.display = 'none';
  });
  document.querySelector('.content-modal').scrollTop = 0;
});

document.querySelector('.menu__right').addEventListener('click', function () {
  document.querySelector('.menu__nav').scrollLeft += 200;
});

document.onkeydown = function (evt) {
  evt = evt || window.event;
  var isEscape = false;
  if ('key' in evt) {
    isEscape = evt.key === 'Escape' || evt.key === 'Esc';
  } else {
    isEscape = evt.keyCode === 27;
  }
  if (isEscape) {
    document.querySelector('body').classList.remove('modal-open');
    document.querySelectorAll('div[data-content*=""]').forEach(function () {
      this.style.display = 'none';
    });
  }
};

function currentMenuItem() {
  for (var i = 0; i < scrollItems.length; i++) {
    if (document.querySelector(scrollItems[i]) !== null) {
      if (
        document.querySelector(scrollItems[i]).getBoundingClientRect().top < 150
      ) {
        var cur = menuItems[i - 1];
      }
    }
  }

  var id = cur;

  if (lastId !== id) {
    lastId = id;

    for (var i = 0; i < menuItems.length; i++) {
      if (menuItems[i] !== null) {
        menuItems[i].parentNode.classList.remove('current-menu-item');
      }
    }

    if (cur.getAttribute('href').substring(1) == 'top') {
      document.querySelector('.menu__nav').scrollLeft = 0;
    } else if (cur !== undefined) {
      cur.parentNode.classList.add('current-menu-item');

      document.querySelector('.menu__nav').scrollLeft = cur.offsetLeft;

      var url = cur.getAttribute('href').substring(1);
      if (url == 'top') url = '/';
      window.history.replaceState({}, '', url);
    }
  }
}

function stickMenu() {
  const hero = document.querySelector('#top');
  const menu = document.querySelector('#menu');
  const body = document.querySelector('body');

  if (hero.getBoundingClientRect().top + hero.offsetHeight < 1) {
    if (!body.classList.contains('stick-menu')) {
      body.classList.add('stick-menu');
    }
  } else {
    if (body.classList.contains('stick-menu')) {
      body.classList.remove('stick-menu');
    }
  }
}

function isInViewport(element, delay = 0) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top + element.offsetHeight >= 0 &&
    rect.left >= 0 &&
    rect.bottom + delay <=
      (window.innerHeight || document.documentElement.clientHeight) +
        element.offsetHeight &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

document
  .querySelectorAll('.numbers__progress-bars .item')
  .forEach(function (elm) {
    window.addEventListener(
      'scroll',
      throttle(10, () => {
        if (isInViewport(elm, 100) && !elm.classList.contains('in-view')) {
          elm.classList.add('in-view');
        }
      })
    );
  });

document.querySelectorAll('.parallax').forEach(function (elm) {
  window.addEventListener(
    'scroll',
    throttle(10, () => {
      if (isInViewport(elm)) {
        var rect = elm.getBoundingClientRect();
        var firstTop = rect.top;
        var winScrollTop = document.body.scrollTop;
        var shiftDistance = (firstTop - winScrollTop) * 0.1;

        elm.style.transform = 'translateY(' + shiftDistance + 'px)';
      }
    })
  );
});

document.querySelectorAll('.parallax-bg').forEach(function (elm) {
  window.addEventListener(
    'scroll',
    throttle(10, () => {
      if (document.documentElement.clientWidth >= 992) {
        if (isInViewport(elm)) {
          var rect = elm.getBoundingClientRect();
          var firstTop = rect.top;
          var winScrollTop = document.body.scrollTop;
          var shiftDistance = (firstTop - winScrollTop) * 0.1 - 150;

          elm.style.backgroundPositionY = shiftDistance + 'px';
        }
      } else {
        elm.style.backgroundPositionY = 0;
      }
    })
  );
});

function toggleReadMore(selector) {
  var $this = $('.hero__main');
  var button = $('#read-more');

  if ($this.hasClass('text-open')) {
    button.html(
      'Toon meer <svg viewBox="0 0 13 7"><path fill="#26264E" fill-rule="evenodd" d="M11.517.313L6.5 5.009 1.483.313C1.088-.096.693-.104.297.287-.1.68-.1 1.061.297 1.436l5.61 5.309c.143.17.341.255.593.255.252 0 .45-.085.593-.255l5.61-5.31c.396-.374.396-.756 0-1.148-.395-.391-.79-.383-1.186.026z"></path></svg>'
    );
    var originalHeight = $this.data('height');
    $this.animate(
      {
        height: originalHeight + 76,
      },
      200,
      function () {
        $(this).removeClass('text-open');
      }
    );
    return false;
  }

  var currentHeight = $this.height();
  var autoHeight = $this.css('height', 'auto').height();
  $this
    .data('height', currentHeight)
    .height(currentHeight)
    .animate(
      {
        height: autoHeight,
      },
      200,
      function () {
        button.html(
          'Toon minder <svg viewBox="0 0 13 7"><path fill="#26264E" fill-rule="evenodd" d="M11.517.313L6.5 5.009 1.483.313C1.088-.096.693-.104.297.287-.1.68-.1 1.061.297 1.436l5.61 5.309c.143.17.341.255.593.255.252 0 .45-.085.593-.255l5.61-5.31c.396-.374.396-.756 0-1.148-.395-.391-.79-.383-1.186.026z"></path></svg>'
        );
        $(this).addClass('text-open');
      }
    );
}

// document.querySelector('#read-more').addEventListener('click', () => {
//   toggleReadMore();
// });

document
  .querySelector('.menu__mobile-trigger')
  .addEventListener('click', () => {
    const body = document.querySelector('body');

    body.classList.toggle('menu-open');

    if (!body.classList.contains('stick-menu')) {
      body.classList.add('stick-menu');
    } else {
      stickMenu();
    }
  });
